<template>
  <div>
    <travel-form
      v-if="edit"
      @cancelEdit="edit = false"
      :tourAgency="tourAgency._id"
      :agencyClients="agencyClients"
      :client="getCurrentClient()"
      :information="{
        name: name,
        place: place,
        startDate: startDate,
        endDate: endDate,
        clients: clients,
        locationTravelMean: locationTravelMean,
        stopover: stopover,
        accompagnist: accompagnist,
        theme: theme,
        messageApp: messageApp,
        travelConv: travelConv,
        roadmapUrl: roadmapUrl,
        ownApp: ownApp,
        watchConversation: watchConversation,
        otherApp: otherApp,
      }"
    ></travel-form>
    <div v-if="!edit" class="display">
      <div class="banner-container">
        <img class="banner" :src="this.getBanner" />
        <div class="banner-tittle">
          <span>{{ name + (isCanceled ? " (CANCELED)" : "") }}</span>
        </div>
      </div>
      <button
        class="edit-button"
        @click="toogleEdit"
        :style="{
          color: $store.getters.color.fontColor,
          backgroundColor: $store.getters.color.color1,
          borderColor: $store.getters.color.color1,
        }"
        v-if="
          isClient() ||
          (tourAgency == this.$store.getters.tourAgencyId &&
            this.$store.getters.userType == 'tourAgencyEmployee') ||
          this.$store.getters.userType == 'travelAssistEmployee'
        "
      >
        {{ $t("buttons.edit") }}
      </button>

      <h2>
        {{ place }} <span class="country">- {{ country }}</span>
      </h2>
      <p v-if="$store.getters.userType != 'travelAssistEmployee'">
        {{ new Date(startDate).toDateString() }} -
        {{ new Date(endDate).toDateString() }}
      </p>
      <div v-else>
        <input type="date" v-model="startDate" />
        <input type="date" v-model="endDate" />
        <button @click="saveNewDates" class="edit-button">Change Dates</button>
      </div>
      <!--div
        v-for="(client, clientIndex) in clients"
        :key="client._id"
        class="client-display"
      >
        <router-link
          :to="buildLink(client._id)"
          :style="{ color: $store.getters.color.color3 }"
        >
          {{
            capitalizeFirstLetter(client.name) +
            " " +
            capitalizeFirstLetter(client.surname)
          }} </router-link
        >-
        <span
          v-for="(accompagnistindiv, accompagnistIndex) in accompagnist[
            clientIndex
          ]"
          :key="accompagnistIndex"
          class="accompagnist-display"
        >
          {{ accompagnistindiv.name }}
          <span v-if="accompagnistIndex != accompagnist[clientIndex].length - 1"
            >,</span
          > </span
        >|
        <span>{{
          messageAppDisplay(
            messageApp[clientIndex].app,
            messageApp[clientIndex].identifier
          )
        }}</span>
      </div-->
      <br />
      <hr />
      <br />
      <router-link :to="'/agency/' + tourAgency._id">{{
        tourAgency.name
      }}</router-link>
      <br />
      <p>Clients:</p>
      <div v-for="client in clients" :key="client._id">
        <router-link :to="'/client/' + client._id">{{
          client.name + " " + client.surname
        }}</router-link>
      </div>

      <br />
      <br />
      <p v-if="telegramInviteLink">Telegram: {{ telegramInviteLink }}</p>
      <div v-for="mean in locationTravelMean" :key="mean.id">
        <span>{{ $t("label.travelForm." + mean.mean) + ": " }}</span>
        <span>{{ mean.information.from + ", " }}</span>
        <span v-if="mean.information.departureDate">
          {{ new Date(mean.information.departureDate).toDateString() + " " }}
        </span>
        <span v-if="mean.information.departureTime">
          {{ mean.information.departureTime }}
        </span>
        <span>{{ " - " + mean.information.to + ", " }}</span>
        <span v-if="mean.information.arrivalDate">
          {{ new Date(mean.information.arrivalDate).toDateString() + " " }}
        </span>
        <span v-if="mean.information.arrivalTime">
          {{ mean.information.arrivalTime }}
        </span>
        <span v-if="mean.information.company">
          {{ mean.information.company }}
        </span>
        <span v-if="mean.information.number">
          {{ mean.information.number }}
        </span>
        <span v-if="mean.information.to">
          {{ mean.information.to }}
        </span>
        <span v-if="mean.information.from">
          {{ mean.information.from }}
        </span>
        <br />
        <br />
      </div>
      <hr />
      <a
        :href="roadmapUrl"
        v-if="roadmapUrl != ''"
        target="_blank"
        :style="{ color: $store.getters.color.color3 }"
        >Roadmap</a
      >
      <br />
      <div v-for="stop in stopover" :key="stop.id">
        <div class="stopover-information">
          <span>
            {{ new Date(stop.startDate).toDateString() }} -
            {{ new Date(stop.endDate).toDateString() }} </span
          >, <span>{{ stop.accomodationName }}</span
          >:
          <span>{{ stop.accomodationLocation }}</span>
        </div>
        <div v-for="activity in stop.activities" :key="activity.id">
          <div>
            <span>*{{ activity.genre }}</span
            >- <span>{{ activity.name }}</span
            >,
            <span v-if="activity.date != 'NaN-NaN-NaN'"
              >{{ new Date(activity.date).toDateString() }} @</span
            >
            <span>{{ activity.time }}*</span>
          </div>
        </div>
        <br />
      </div>
      <div
        class="prog-messages"
        v-if="
          ($store.getters.userType == 'travelAssistEmployee' ||
            $store.getters.userType == 'tourAgencyEmployee') &&
          typeof this.progMessages === 'object' /*temporary*/
        "
      >
        <p
          class="prog-messages-header"
          :style="{
            backgroundColor: $store.getters.color.color1,
            color: $store.getters.color.fontColor,
          }"
        >
          <span class="float-left" v-html="$twemoji.parse('🕑')"></span>
          <span class="float-left">{{ $t("label.progMessages") }}</span>
          <span class="plus-sign" @click="newPm">+</span>
        </p>
        <div class="prog-messages-container">
          <span
            v-for="(msg, msgId) in progMessages"
            :key="msg._id"
            class="prog-messages-msg"
            @click="showModal(msgId)"
            >{{
              (msg.timestamp ? $d(new Date(msg.timestamp), "long") : "") +
              (msg.sent ? " (Sent)" : "")
            }}</span
          >
        </div>
      </div>
      <modal
        name="progMessages-modal"
        :scrollable="true"
        :adaptive="true"
        width="90%"
        :maxWidth="600"
        height="auto"
        @closed="closeModal"
      >
        <div class="modal-date">
          <label for="delivery-date">{{ $t("label.deliveryDate") }}</label>
          <p>
            {{
              currentProgMessage.timestamp
                ? $d(new Date(currentProgMessage.timestamp), "long")
                : ""
            }}
          </p>
          <input type="date" v-model="currentProgMessage.date" />
          <input type="time" v-model="currentProgMessage.time" />
        </div>
        <textarea
          class="modal-textarea"
          v-model="currentProgMessage.message.body"
        ></textarea>
        <br />
        <!--span v-if="this.currentProgMessage.doc && !docInfo.name">
          {{ this.currentProgMessage.doc.name }}
        </span>
        <span v-if="docInfo.name">{{ docInfo.name }}</span>
        <label for="fileUpload" class="upload-documents">Join</label>
        <input
          type="file"
          name="fileUpload"
          id="fileUpload"
          accept=".pdf"
          @change="parseUploadedFile"
        />
        <br /-->
        <button @click="deletePm" class="delete-button">
          {{ $t("buttons.delete") }}
        </button>
        <button class="save-button" @click="savePm">
          {{ $t("buttons.save") }}
        </button>
      </modal>
      <br />
      <br />
      <a v-if="roadmapLink" target="_blank" :href="roadmapLink">{{
        $t("label.travelForm.roadmap")
      }}</a>
      <br />
      <button
        v-if="this.$store.getters.userType == 'travelAssistEmployee'"
        class="edit-button"
        @click="openRoadmapModal"
        :style="{
          color: $store.getters.color.fontColor,
          backgroundColor: $store.getters.color.color1,
          borderColor: $store.getters.color.color1,
        }"
      >
        {{ $t("label.travelForm.uploadRoadmap") }}
      </button>
      <br />
      <ul>
        <li v-for="fileUrl in files" :key="fileUrl.url">
          <a target="_blank" :href="fileUrl.url">{{
            decodeURIComponent(fileUrl.url.split("/").at(-1).split("?")[0])
          }}</a>
        </li>
      </ul>
      <br />
      <modal
        name="roadmapUpload-modal"
        :scrollable="true"
        :adaptive="true"
        width="90%"
        :maxWidth="600"
        height="auto"
      >
        <div class="roadmap">
          <!--a
            v-if="this.roadmapUrl != '' && this.roadmapUrl != undefined"
            :href="this.roadmapUrl"
            target="_blank"
            >{{ $t("label.travelForm.roadmap") }}</a
          -->
          <!--label
            for="fileUpload"
            class="upload-documents"
            :style="{
              color: $store.getters.color.fontColor,
              backgroundColor: $store.getters.color.color1,
            }"
            >{{ $t("label.travelForm.uploadRoadmap") }}</label
          -->
          <input ref="fileUpload" type="file" accept=".pdf" />
          <button class="edit-button" @click="uploadRoadmap">Upload</button>
        </div>
      </modal>
      <router-link
        type="button"
        v-if="
          (this.$store.getters.userType === 'travelAssistEmployee' ||
            this.$store.getters.userType === 'contactCenterAgent') &&
          typeof this.progMessages === 'object' /*temporary*/ /*||
          (this.$store.getters.userType == 'tourAgencyEmployee' &&
            (this.watchConversation || this.ownApp) &&
            this.$store.getters.tourAgencyId == this.tourAgency._id)*/
        "
        class="edit-button"
        :to="'/traveltchat/' + this.$route.params.id"
        >{{ $t("buttons.seeTchat") }}</router-link
      >
      <br />
      <br />
      <router-link
        v-if="
          (this.invoice &&
            this.invoice.id &&
            this.$store.state.userType !== 'client' &&
            this.$store.state.tourAgencyId !== '') ||
          (this.invoice.id !== '' &&
            this.$store.state.userType === 'client' &&
            this.$store.state.userId == this.invoice.client &&
            this.$store.state.tourAgencyName === 'TravelAssist.io')
        "
        class="invoiceButton"
        :to="'/invoice/' + this.invoice.id + '/' + this.$i18n.locale"
        target="_top"
        :style="{
          color: $store.getters.color.fontColor,
          backgroundColor: $store.getters.color.color1,
          borderColor: $store.getters.color.color1,
        }"
        >Télécharger Facture</router-link
      >
      <button
        v-if="
          this.$store.getters.userType == 'travelAssistEmployee' &&
          !this.isCanceled
        "
        class="cancel-button"
        @click="cancelTravel"
      >
        CANCEL TRAVEL
      </button>
    </div>
  </div>
</template>

<script>
import HandleApi, { azureSasUrl } from "../apiHandle";
import clientTemplateSearch from "./ClientTemplateSearch.vue";
import TravelForm from "./TravelForm.vue";
import sdk from "matrix-js-sdk";
import { BlobServiceClient, BlockBlobClient } from "@azure/storage-blob";

export default {
  name: "TravelDisplay",
  components: { TravelForm },
  data() {
    return {
      invoice: {},
      name: "",
      place: "",
      country: "",
      banner: "",
      startDate: "",
      endDate: "",
      clients: [],
      locationTravelMean: [],
      stopover: [],
      accompagnist: [],
      clients: [],
      clientSearch: {
        id: "",
        name: "",
        surname: "",
      },
      clientTemplateSearch: clientTemplateSearch,
      items: [],
      accompagnistInput: [],
      edit: false,
      tourAgency: "",
      agencyClients: [],
      messageApp: [],
      otherApp: [],
      theme: "",
      inviteFriends: "",
      password: "",
      travelConv: "",
      roadmapUrl: "",
      ownApp: false,
      watchConversation: false,
      progMessages: [],
      currentProgMessage: {
        message: {
          body: "",
        },
      },
      modalHour: 0,
      modalMinute: 0,
      docStorage: null,
      docInfo: { type: null, size: null, url: null, name: null },
      telegramInviteLink: "",
      files: [],
      containerClient: new BlobServiceClient(azureSasUrl).getContainerClient(
        "travel" + this.$route.params.id
      ),
      roadmapLink: "",
      isCanceled: false,
    };
  },
  async created() {
    if (this.$store.getters.travel_id == this.$route.params.id) {
      try {
        let res = await HandleApi.checkTravelPassword(this.$route.params.id, {
          _id: this.$store.getters.userId,
          password: this.$store.getters.travel_password,
        });
        if (res.status == 401) {
          this.$router.push("/401");
        } else {
          if (res.status == 403) {
            this.$router.push("/403");
          } else {
            this.$store.dispatch("setJoinTravel", {
              travel_password: "",
              travel_id: "",
            });
            this.getInformation();
          }
        }
      } catch (err) {
        this.$store.dispatch("push_error_message", err);
      }
    } else {
      this.getInformation();
      /*       if (this.$route.query.roadmapUpload) {
        this.$modal.show("roadmapUpload-modal");
      } */
    }
  },
  computed: {
    errorContent: function () {
      if (new Date(this.endDate) < new Date(this.startDate)) {
        return this.$t("travel.dateError");
      }
      for (let i = 0; i < this.clients.length; i++) {
        if (this.messageApp[i].app != this.messageApp[0].app) {
          return this.$t("travel.appError");
        }
      }
      return "";
    },
    checkForm: function () {
      if (!this.name) {
        return true;
      }
      if (!this.place) {
        return true;
      }
      if (!this.theme) {
        return true;
      }
      if (!this.startDate) {
        return true;
      }
      if (!this.endDate) {
        return true;
      }
      if (this.clients.length == 0) {
        return true;
      }
      for (let i = 0; i < this.clients.length; i++) {
        if (this.messageApp[i].app != this.messageApp[0].app) {
          return true;
        }
      }
      for (let i = 0; i < this.stopover.length; i++) {
        if (
          !(
            this.stopover[i].place &&
            this.stopover[i].startDate &&
            this.stopover[i].endDate &&
            this.stopover[i].accomodationName &&
            this.stopover[i].accomodationLocation
          )
        ) {
          return true;
        }
      }

      for (let i = 0; i < this.messageDateList; i++) {
        if (this.messageDateList[i] != "") {
          return true;
        }
      }
      if (this.errorContent != "") {
        return true;
      }
      return false;
    },
    messageDateList: function () {
      let res = [];
      for (let i = 0; i < this.stopover.length; i++) {
        let message = "";
        if (
          new Date(this.stopover[i].startDate) >
          new Date(this.stopover[i].endDate)
        ) {
          message = this.$t("travel.stopDateError");
        }
        if (new Date(this.startDate) > new Date(this.stopover[i].startDate)) {
          message = this.$t("travel.stopStartDateError");
        }
        if (new Date(this.endDate) < new Date(this.stopover[i].endDate)) {
          message = this.$t("travel.stopEndDateError");
        }
        res.push(message);
      }
      return res;
    },
    getBanner: function () {
      if (this.banner == "") {
        return "https://www.travelassist.io/assets/default-banner.jpg";
      } else {
        return this.banner;
      }
    },
  },
  methods: {
    showModal(msgId) {
      this.currentProgMessage = this.progMessages[msgId];
      // this might break everything
      this.currentProgMessage.date = this.currentProgMessage.timestamp;
      this.currentProgMessage.time = new Date(this.currentProgMessage.timestamp)
        .toTimeString()
        .slice(0, 5);
      /*if (this.progMessages[msgId].doc) {
        this.currentProgMessage["doc"] = this.progMessages[msgId].doc;
      }
      this.currentProgMessage.deliveryDate = new Date(
        this.currentProgMessage.deliveryDate
      );
      this.modalHour = this.currentProgMessage.deliveryDate.getHours();
      this.modalMinute = this.currentProgMessage.deliveryDate.getMinutes();
      this.currentProgMessage.deliveryDate = this.niceDate(
        this.currentProgMessage.deliveryDate
      );*/
      this.$modal.show("progMessages-modal");
    },
    newPm() {
      //this.currentProgMessage = { messageTxt: "", deliveryDate: new Date() };
      this.currentProgMessage = {
        message: {
          msgtype: "m.text",
          body: "",
        },
      };
      /*this.modalMinute =
        (Math.floor(this.currentProgMessage.deliveryDate.getMinutes() / 5) +
          2) *
        5;
      this.modalHour =
        this.modalMinute >= 60
          ? this.currentProgMessage.deliveryDate.getHours() + 1
          : this.currentProgMessage.deliveryDate.getHours();
      this.modalMinute = this.modalMinute % 60;
      this.currentProgMessage.deliveryDate = this.niceDate(
        this.currentProgMessage.deliveryDate
      );*/
      this.$modal.show("progMessages-modal");
    },
    async deletePm() {
      // TODO
      try {
        //await HandleApi.deletePm(this.currentProgMessage._id);
        await HandleApi.deleteScheduledMessage(this.currentProgMessage._id);
        this.$store.dispatch(
          "push_notification_message",
          this.$t("notification.deleted", { object: "pm" })
        );
        this.$router.go();
      } catch (err) {
        this.$store.dispatch("push_error_message", err.response.data || err);
      }
    },
    saveNewDates() {
      HandleApi.changeTravelDates(this.$route.params.id, {
        startDate: this.startDate,
        endDate: this.endDate,
      })
        .then(() => {
          this.$store.dispatch("push_notification_message", "Travel updated");
        })
        .catch((err) => this.$store.dispatch("push_error_message", err));
    },
    getCurrentClient() {
      for (let i = 0; i < this.clients.length; i++) {
        if (this.clients[i]._id == this.$store.getters.userId) {
          return this.clients[i];
        }
      }
      return {};
    },
    messageAppDisplay(app, identifier) {
      switch (app) {
        case "matrix":
          if (
            this.$store.getters.tourAgencyId !=
            this.$store.getters.travelAssistAgencyId
          ) {
            return this.$t("label.geniusTravelTchat");
          } else {
            return this.$t("label.TATchat");
          }
        case "personal_matrix":
          return "matrix (" + identifier + ")";
        default:
          return app + " (" + identifier + ")";
      }
    },
    capitalizeFirstLetter: function (string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    niceDate: function (dat) {
      let month = dat.getMonth();
      if (month < 9) {
        month = "0" + (month + 1);
      } else {
        month = month + 1;
      }
      let date = dat.getDate();
      if (date < 10) {
        date = "0" + date;
      }
      return dat.getFullYear() + "-" + month + "-" + date;
    },
    getInformation: async function () {
      try {
        let information = {};
        let tempclient;
        if (this.$store.getters.userType == "contactCenterAgent") {
          information = await HandleApi.getTravelInformationMinified(
            this.$route.params.id
          );
          tempclient = information.clients;
          information = information.travel;
        } else {
          information = await HandleApi.getTravelInformation(
            this.$route.params.id
          );
        }
        this.name = this.capitalizeFirstLetter(information.name)
          .replace(/&amp;/gi, "&")
          .replace(/&#x27;/gi, "'");
        this.place = this.capitalizeFirstLetter(information.place)
          .replace(/&amp;/gi, "&")
          .replace(/&#x27;/gi, "'");
        this.banner = information.banner;
        this.country = information.country;
        this.startDate = this.niceDate(new Date(information.startDate));
        this.endDate = this.niceDate(new Date(information.endDate));
        this.theme = information.theme;
        this.password = information.password;
        this.travelConv = information.matrixRoomId;
        this.ownApp = information.ownApp;
        this.watchConversation = information.watchConversation;
        this.telegramInviteLink = information.telegramInviteLink;
        if (information.client.name) {
          this.clients = information.client;
        } else if (tempclient) {
          this.clients = tempclient;
        }
        if (information.invoice) {
          this.invoice = {
            id: information.invoice._id,
            client: information.invoice.customerInfo.client,
          };
        }
        this.files = information.files || [];
        if (information.hasOwnProperty("client")) {
          for (const el of information.client) {
            if (el.client_id.name && el.client_id.surname) {
              this.clients.push({
                _id: el.client_id._id,
                name: el.client_id.name,
                surname: el.client_id.surname,
              });
            }
          }
        }
        /*if (information.hasOwnProperty("clients")) {
          this.clients = information.clients;
          for (let i = 0; i < information.clients.length; i++) {
            this.accompagnistInput.push({ name: "", age: 1 });
            if (information.hasOwnProperty("client")) {
              let associated = this.associatedAccompagnist(
                this.clients[i]._id,
                information.client
              );
              this.accompagnist.push(associated.accompagnist);
              let j = 0;
              while (
                j < this.clients[i].messageApp.length &&
                !(
                  associated.messageApp.app ==
                    this.clients[i].messageApp[j].app &&
                  associated.messageApp.identifier ==
                    this.clients[i].messageApp[j].identifier
                )
              ) {
                j++;
              }
              if (j == this.clients[i].messageApp.length) {
                this.otherApp.push(associated.messageApp);
                this.messageApp.push({ app: "other", identifier: "" });
              } else {
                this.messageApp.push(associated.messageApp);
                this.otherApp.push({ app: "", identifier: "" });
              }
            }
          }
        }*/

        if (
          information.hasOwnProperty("tourAgency") &&
          information.tourAgency._id != this.$store.getters.travelAssistAgencyId
        ) {
          this.tourAgency = information.tourAgency;
        }
        if (information.hasOwnProperty("stopover")) {
          this.stopover = information.stopover;
        }
        if (information.hasOwnProperty("locationTravelMean")) {
          this.locationTravelMean = information.locationTravelMean;
        }
        if (information.hasOwnProperty("roadmap")) {
          this.roadmapUrl = information.roadmap;
        }
        if (
          this.tourAgency &&
          this.$store.getters.userType == "tourAgencyEmployee"
        ) {
          try {
            let agencyInformation = await HandleApi.getAgencyInformation(
              this.tourAgency._id
            );
            if (agencyInformation.hasOwnProperty("agency_client")) {
              this.items = agencyInformation.agency_client;
            }
          } catch (err) {
            this.$store.dispatch("push_error_message", err);
          }
        }
        if (
          this.$store.getters.userType == "travelAssistEmployee" ||
          this.$store.getters.userType == "tourAgencyEmployee"
        ) {
          // Temporary while there are still unmigrated travels
          try {
            this.progMessages = await HandleApi.getScheduledMessages(
              this.$route.params.id
            );
            if (!this.progMessages) {
              this.progMessages = [];
            }
          } catch (e) {}
        }
        if (information.isCanceled) {
          this.isCanceled = true;
        }
        if (await this.containerClient.exists()) {
          const roadmapBlobClient =
            this.containerClient.getBlockBlobClient("roadmap.pdf");
          if (await roadmapBlobClient.exists()) {
            this.roadmapLink =
              azureSasUrl.split("?")[0] +
              "travel" +
              this.$route.params.id +
              "/roadmap.pdf?" +
              azureSasUrl.split("?")[1];
          }
        }
      } catch (err) {
        this.$store.dispatch("push_error_message", err);
      }
    },
    getBack: function () {
      this.edit = false;
      this.getInformation();
    },
    associatedAccompagnist(targetclient_id, accompagnist_list) {
      let i = 0;
      while (
        i < this.accompagnist.length &&
        accompagnist_list[i].client_id != targetclient_id
      ) {
        i++;
      }
      return i < accompagnist_list.length ? accompagnist_list[i] : [];
    },
    buildLink(clientId) {
      return "/client/" + clientId;
    },
    toogleEdit: function () {
      this.edit = true;
    },
    isClient() {
      if (this.tourAgency._id == this.$store.getters.tourAgencyId) {
        return true;
      }
      for (let i = 0; i < this.clients.length; i++) {
        if (this.clients[i]._id == this.$store.getters.userId) {
          return true;
        }
      }
      return false;
    },
    closeModal() {
      this.currentProgMessage = { message: { body: "" } };
      this.modalHour = 0;
      this.modalMinute = 0;
      this.docStorage = null;
      this.docInfo = { type: null, size: null, url: null, name: null };
    },
    async parseUploadedFile(event) {
      let file = event.target.files[0];
      this.docStorage = file;
      this.docInfo.type = file.type;
      this.docInfo.size = file.size;
      this.docInfo.name = file.name;
    },
    async savePm() {
      // TODO
      try {
        this.$store.dispatch("load", "Saving ...");
        const date = new Date(this.currentProgMessage.date);
        date.setHours(this.currentProgMessage.time.split(":")[0]);
        date.setMinutes(this.currentProgMessage.time.split(":")[1]);
        if (this.currentProgMessage._id) {
          await HandleApi.putScheduledMessage(
            this.currentProgMessage._id,
            this.currentProgMessage.message,
            date.getTime()
          );
        } else {
          await HandleApi.postScheduledMessage(
            this.$route.params.id,
            this.currentProgMessage.message,
            date.getTime()
          );
        }
        /*info.deliveryDate = new Date(this.currentProgMessage.deliveryDate);
        info.deliveryDate.setHours(this.modalHour);
        info.deliveryDate.setMinutes(this.modalMinute);
        info.tourAgency = this.tourAgency._id;
        info.travel = this.$route.params.id;
        info.messageTxt = this.currentProgMessage.messageTxt;
        if (this.docStorage) {
          let res = await HandleApi.getTravelMatrix(this.$route.params.id);
          let client = sdk.createClient({
            baseUrl: res.baseUrl,
            accessToken: res.accessToken,
            userId: res.userId,
          });
          this.roadmapUrl = await client.uploadContent(
            this.docStorage,
            this.travelConv
          );
          this.docInfo.url = this.roadmapUrl;
          info.doc = this.docInfo;
        }
        if (this.currentProgMessage._id) {
          await HandleApi.putPm(this.currentProgMessage._id, info);
        } else {
          await HandleApi.postPm(info);
        }*/
        this.$store.dispatch("unload");
        this.$router.go();
      } catch (err) {
        this.$store.dispatch("unload");
        this.$store.dispatch("push_error_message", err.response.data || err);
        this.$modal.hide("progMessages-modal");
      }
    },
    openRoadmapModal() {
      this.$modal.show("roadmapUpload-modal");
    },
    async uploadRoadmap(/*event*/) {
      try {
        await this.containerClient.createIfNotExists();
        const roadmapBlobClient = this.containerClient.getBlockBlobClient(
          "roadmap.pdf" /*this.$refs.fileUpload.files[0].name*/
        );
        await roadmapBlobClient.uploadBrowserData(
          this.$refs.fileUpload.files[0]
        );
        this.$router.go();
        /*if (this.travelConv) {
          this.$store.dispatch("load", "Uploading your roadmap");
          let res = undefined;
          if (this.$store.getters.userType == "client") {
            res = await HandleApi.getMessageAuth(this.$store.getters.userId);
          } else {
            res = await HandleApi.getTravelMatrix(this.$route.params.id);
          }
          let client = sdk.createClient({
            baseUrl: res.baseUrl,
            accessToken: res.accessToken,
            userId: res.userId,
          });
          let file = event.target.files[0];
          this.roadmapUrl = await client.uploadContent(file, this.travelConv);
          let docUrl = this.roadmapUrl.substring(6);
          this.roadmapUrl =
            res.baseUrl + "/_matrix/media/r0/download/" + docUrl;
          await client.sendEvent(
            this.travelConv,
            "m.room.message",
            {
              body: "A Roadmap has been uploaded for this travel",
              msgtype: "m.notice",
              format: "org.matrix.custom.html",
              formatted_body:
                "<p>" +
                "A Roadmap has been uploaded for this travel you can look at it here: " +
                "<a href='" +
                this.roadmapUrl +
                "'>Roadmap</a>" +
                "</p>",
            },
            ""
          );
          await HandleApi.uploadRoadmap(this.$route.params.id, {
            roadmap: this.roadmapUrl,
          });
          this.$store.dispatch(
            "push_notification_message",
            this.$t("notification.updated", {
              object: this.$t("notification.roadmap"),
            })
          );
          this.$store.dispatch("unload");
        }*/
      } catch (err) {
        this.$store.dispatch("push_error_message", err);
        this.$store.dispatch("unload");
      }
      this.$modal.hide("roadmapUpload-modal");
    },
    async cancelTravel() {
      await HandleApi.putTravel(this.$route.params.id, { isCanceled: true });
      this.$router.push("/search");
    },
  },
};
</script>

<style scoped>
.edit-div {
  text-align: center;
  margin-top: 20px;
  line-height: 40px;
}

.edit-div label {
  margin-right: 10px;
  margin-left: 10px;
}

.error-message {
  border: 2px solid red;
  color: red;
  background-color: #ecb2b2;
  width: 500px;
  margin: 0 auto;
  border-radius: 10px;
}

.app-container {
  display: inline-block;
}

.display {
  text-align: center;
  margin: 0 auto;
}

.display hr {
  width: 50%;
}

.display h1 {
  display: inline-block;
  margin-right: 10px;
}

.edit-button {
  box-shadow: 2px 2px 2px lightgrey;
  color: white;
  border: 2px solid #00909f;
  font-size: 17px;
  background-color: #00909f;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 7px;
  margin: auto 0;
}

.edit-button:hover {
  text-decoration: underline;
}

a {
  color: #206874;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.client-display {
  margin-bottom: 0px;
}

.accompagnist-display {
  margin: 0 3px;
}

.stopover-information {
  font-weight: bold;
}

.v-autocomplete-list {
  border: 1px solid black;
}

.invoiceButton {
  padding: 10px 30px;
}

.prog-messages {
  border: none;
  border-radius: 10px;
  display: inline-block;
  max-width: 70%;
}

.prog-messages-header {
  border-radius: 10px 10px 0 0;
  padding: 5px;
  text-align: right;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 0;
}

.prog-messages-header span {
  margin-right: 5px;
}

.prog-messages-container {
  overflow-y: hide;
  border: 1px solid lightgrey;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 0 0 10px 10px;
  padding: 10px;
}

.prog-messages-container span {
  margin: 5px;
}

.prog-messages-container span:hover {
  text-decoration: underline;
  cursor: pointer;
}

.modal-textarea {
  resize: none;
  height: 300px;
  width: 90%;
  margin-bottom: 10px;
}

.modal-date {
  margin: 10px;
}
.upload-documents {
  padding: 5px;
  border-radius: 10px;
  color: white;
  background-color: #00909f;
  font-weight: bold;
  margin-left: 5px;
}

.upload-documents:hover {
  text-decoration: underline;
  cursor: pointer;
}

.upload-roadmap {
  padding: 5px;
  border-radius: 10px;
  color: white;
  background-color: #00909f;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: bold;
  display: inline-block;
}

.upload-roadmap:hover {
  text-decoration: underline;
  cursor: pointer;
}

.upload-roadmap-input {
  margin-bottom: 10px;
}

.save-button {
  background-color: rgb(9, 201, 111);
  color: white;
  border: none;
  border-radius: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
  margin-top: 20px;
  font-weight: bold;
  padding: 5px 20px;
  font-size: 20px;
}

.save-button:hover {
  cursor: pointer;
}

.cancel-button {
  background-color: red;
  color: white;
  border: none;
  border-radius: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
  margin-top: 20px;
  font-weight: bold;
  padding: 5px 20px;
  font-size: 20px;
}

.cancel-button:hover {
  cursor: pointer;
}

.float-left {
  float: left;
}

.plus-sign {
  border: solid 2px white;
  padding-right: 5px;
  padding-left: 5px;
}

.plus-sign:hover {
  cursor: pointer;
}

.delete-button {
  background-color: #aa1853;
  color: white;
  border: none;
  border-radius: 10px;
  margin-right: 10px;
  margin-top: 20px;
  margin-bottom: 10px;
  font-weight: bold;
  padding: 5px 20px;
  font-size: 20px;
}

.delete-button:hover {
  cursor: pointer;
}

.banner-container {
  width: 100%;
  position: relative;
  z-index: -1;
}
.banner {
  width: 100%;
  height: 300px;
  object-fit: cover;
  object-position: 50% 50%;
}
.banner-tittle {
  width: 100%;
  position: absolute;
  bottom: 5px;
  background-color: rgba(0, 0, 0, 0.8);
}
.banner-tittle span {
  font-weight: bold;
  color: white;
  font-size: 2em;
}
.country {
  font-weight: normal;
}
li {
  list-style: none;
}
</style>
