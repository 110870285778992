<template>
  <div class="edit-div">
    <p class="error-message" v-if="errorContent != ''">{{ errorContent }}</p>
    <br v-if="errorContent != ''" />
    <div class="intro-mean-container">
      <div
        class="travel-intro"
        :style="{ boxShadow: '1px 1px 5px ' + $store.getters.color.color3 }"
      >
        <p
          class="transportation-container-title"
          :style="{
            backgroundColor: $store.getters.color.color1,
            color: $store.getters.color.fontColor,
          }"
        >
          {{ $t("label.travelForm.genericInformation") }}
        </p>
        <!--label for="name">{{ $t("label.name") }} *</label>
        <input type="text" id="name" v-model="name" required />
        <br /-->
        <label for="place">{{ $t("label.travelForm.place") }} *</label>
        <input type="text" id="place" v-model="place" required />
        <br />
        <label for="country">{{ $t("label.contractForm.country") }} *</label>
        <country-list id="country"></country-list>
        <br />
        <label for="theme">{{ $t("label.travelForm.thema") }} *</label>
        <select v-model="themeSelect" required>
          <option value="Family Vacations">
            {{ $t("label.travelForm.familyVacations") }}
          </option>
          <option value="Birthday">
            {{ $t("label.travelForm.birthday") }}
          </option>
          <option value="Celebration">
            {{ $t("label.travelForm.celebration") }}
          </option>
          <option value="Surprise">
            {{ $t("label.travelForm.surprise") }}
          </option>
          <option value="Couple Vacations">
            {{ $t("label.travelForm.coupleVacations") }}
          </option>
          <option value="With Friends">
            {{ $t("label.travelForm.withFriends") }}
          </option>
          <option value="Music">{{ $t("label.travelForm.music") }}</option>
          <option value="Sport">{{ $t("label.travelForm.sport") }}</option>
          <option value="Discovery">
            {{ $t("label.travelForm.discovery") }}
          </option>
          <option value="Other">{{ $t("label.other") }}</option>
        </select>
        <input
          type="text"
          v-model="otherThemeInput"
          v-show="themeSelect == 'Other'"
        />
        <br />
        <label for="startDate">{{ $t("label.travelForm.startDate") }} *</label>
        <input
          type="date"
          id="startDate"
          v-model="startDate"
          :disabled="Object.keys(this.information).length > 0"
          required
        />
        <br />
        <label for="endDate">{{ $t("label.travelForm.endDate") }} *</label>
        <input
          type="date"
          id="endDate"
          v-model="endDate"
          :disabled="Object.keys(this.information).length > 0"
          required
        />
        <br />
      </div>
      <div
        class="location-travel-mean-container"
        :style="{ boxShadow: '1px 1px 5px ' + $store.getters.color.color3 }"
      >
        <p
          class="transportation-container-title"
          :style="{
            backgroundColor: $store.getters.color.color1,
            color: $store.getters.color.fontColor,
          }"
        >
          {{ $t("label.travelForm.transportation") }}
        </p>
        <div
          class="location-travel-mean"
          v-for="(transportation, transportationIndex) in locationTravelMean"
          :key="transportation.id"
        >
          <select id="locationTravelMean" v-model="transportation.mean">
            <option value="plane">{{ $t("label.travelForm.plane") }}</option>
            <option value="boat">{{ $t("label.travelForm.boat") }}</option>
            <option value="car">{{ $t("label.travelForm.car") }}</option>
            <option value="bus">{{ $t("label.travelForm.bus") }}</option>
            <option value="train">{{ $t("label.travelForm.train") }}</option>
            <option value="bike">{{ $t("label.travelForm.bike") }}</option>
            <option value="walking">
              {{ $t("label.travelForm.walking") }}
            </option>
          </select>
          <div
            class="transport-information"
            v-show="transportation.mean == 'boat'"
          >
            <span>{{ $t("label.travelForm.company") }}</span>
            <input type="text" v-model="transportation.information.company" />
            <span>{{ $t("label.travelForm.number") }}</span>
            <input type="text" v-model="transportation.information.number" />
            <br />
            <span>{{ $t("label.travelForm.from") }} *</span>
            <input type="text" v-model="transportation.information.from" />
            <span>{{ $t("label.travelForm.departureDate") }}</span>
            <input
              type="date"
              v-model="transportation.information.departureDate"
            />
            <span>{{ $t("label.travelForm.departureTime") }}</span>
            <input
              type="time"
              v-model="transportation.information.departureTime"
            />
            <br />
            <span>{{ $t("label.travelForm.to") }} *</span>
            <input type="text" v-model="transportation.information.to" />
            <span>{{ $t("label.travelForm.arrivalDate") }}</span>
            <input
              type="date"
              v-model="transportation.information.arrivalDate"
            />
            <span>{{ $t("label.travelForm.arrivalTime") }}</span>
            <input
              type="time"
              v-model="transportation.information.arrivalTime"
            />
          </div>
          <div
            class="transport-information"
            v-show="transportation.mean == 'bus'"
          >
            <span>{{ $t("label.travelForm.company") }}</span>
            <input type="text" v-model="transportation.information.company" />

            <span>{{ $t("label.travelForm.number") }}</span>
            <input type="text" v-model="transportation.information.number" />
            <br />
            <span>{{ $t("label.travelForm.from") }} *</span>
            <input type="text" v-model="transportation.information.from" />

            <span>{{ $t("label.travelForm.departureDate") }}</span>
            <input
              type="date"
              v-model="transportation.information.departureDate"
            />

            <span>{{ $t("label.travelForm.departureTime") }}</span>
            <input
              type="time"
              v-model="transportation.information.departureTime"
            />
            <br />
            <span>{{ $t("label.travelForm.to") }} *</span>
            <input type="text" v-model="transportation.information.to" />

            <span>{{ $t("label.travelForm.arrivalDate") }}</span>
            <input
              type="date"
              v-model="transportation.information.arrivalDate"
            />

            <span>{{ $t("label.travelForm.arrivalTime") }}</span>
            <input
              type="time"
              v-model="transportation.information.arrivalTime"
            />
          </div>
          <div
            class="transport-information"
            v-show="transportation.mean == 'train'"
          >
            <span>{{ $t("label.travelForm.company") }}</span>
            <input type="text" v-model="transportation.information.company" />

            <span>{{ $t("label.travelForm.number") }}</span>
            <input type="text" v-model="transportation.information.number" />
            <br />
            <span>{{ $t("label.travelForm.from") }} *</span>
            <input type="text" v-model="transportation.information.from" />

            <span>{{ $t("label.travelForm.departureDate") }}</span>
            <input
              type="date"
              v-model="transportation.information.departureDate"
            />

            <span>{{ $t("label.travelForm.departureTime") }}</span>
            <input
              type="time"
              v-model="transportation.information.departureTime"
            />
            <br />
            <span>{{ $t("label.travelForm.to") }} *</span>
            <input type="text" v-model="transportation.information.to" />

            <span>{{ $t("label.travelForm.arrivalDate") }}</span>
            <input
              type="date"
              v-model="transportation.information.arrivalDate"
            />
            <span>{{ $t("label.travelForm.arrivalTime") }}</span>
            <input
              type="time"
              v-model="transportation.information.arrivalTime"
            />
          </div>
          <div
            class="transport-information"
            v-show="transportation.mean == 'plane'"
          >
            <span>{{ $t("label.travelForm.company") }}</span>
            <input type="text" v-model="transportation.information.company" />

            <span>{{ $t("label.travelForm.number") }}</span>
            <input type="text" v-model="transportation.information.number" />
            <br />
            <span>{{ $t("label.travelForm.from") }} *</span>
            <input type="text" v-model="transportation.information.from" />

            <span>{{ $t("label.travelForm.departureDate") }}</span>
            <input
              type="date"
              v-model="transportation.information.departureDate"
            />

            <span>{{ $t("label.travelForm.departureTime") }}</span>
            <input
              type="time"
              v-model="transportation.information.departureTime"
            />
            <br />
            <span>{{ $t("label.travelForm.to") }} *</span>
            <input type="text" v-model="transportation.information.to" />

            <span>{{ $t("label.travelForm.arrivalDate") }}</span>
            <input
              type="date"
              v-model="transportation.information.arrivalDate"
            />
            <span>{{ $t("label.travelForm.arrivalTime") }}</span>
            <input
              type="time"
              v-model="transportation.information.arrivalTime"
            />
          </div>
          <div
            class="transport-information"
            v-show="transportation.mean == 'car'"
          >
            <span>{{ $t("label.travelForm.from") }} *</span>
            <input type="text" v-model="transportation.information.from" />
            <span>{{ $t("label.travelForm.to") }} *</span>
            <input type="text" v-model="transportation.information.to" />
          </div>
          <div
            class="transport-information"
            v-show="transportation.mean == 'bike'"
          >
            <span>{{ $t("label.travelForm.from") }} *</span>
            <input type="text" v-model="transportation.information.from" />
            <span>{{ $t("label.travelForm.to") }} *</span>
            <input type="text" v-model="transportation.information.to" />
          </div>
          <div
            class="transport-information"
            v-show="transportation.mean == 'walking'"
          >
            <span>{{ $t("label.travelForm.from") }}</span>
            <input type="text" v-model="transportation.information.from" />
            <span>{{ $t("label.travelForm.to") }}</span>
            <input type="text" v-model="transportation.information.to" />
          </div>
          <button
            type="button"
            @click="locationTravelMean.splice(transportationIndex, 1)"
            v-if="transportationIndex != 0"
          >
            -
          </button>
        </div>
        <button
          type="button"
          @click="locationTravelMean.push({ mean: 'plane', information: {} })"
        >
          +
        </button>
      </div>
    </div>
    <!--div class="roadmap" v-if="this.information.hasOwnProperty('name')">
      <a
        v-if="this.roadmapUrl != '' && this.roadmapUrl != undefined"
        :href="this.roadmapUrl"
        target="_blank"
        >{{ $t("label.travelForm.roadmap") }}</a
      >
      <label
        for="fileUpload"
        class="upload-documents"
        :style="{
          color: $store.getters.color.fontColor,
          backgroundColor: $store.getters.color.color1,
        }"
        >{{ $t("label.travelForm.uploadRoadmap") }}</label
      >
      <input
        type="file"
        name="fileUpload"
        id="fileUpload"
        accept=".pdf"
        @change="parseUploadedFile"
      />
    </div-->
    <label for="messageApp">Application : </label>
    <select id="messageApp" v-model="messageApp">
      <option value="telegram">Telegram</option>
      <!-- <option value="matrix">GeniusTravel.io</option> -->
    </select>
    <h4>{{ $t("label.addedClient") }} :</h4>
    <div class="clients">
      <p v-if="clients.length <= 0">{{ $t("label.noClient") }}</p>
      <p v-for="(c, idx) in clients" :key="c.id">
        {{ c.name + " " + c.surname }}
        <span class="delete" @click="deleteClient(idx)">{{
          $t("buttons.delete")
        }}</span>
      </p>
    </div>
    <!--div class="big-form" v-if="clients.length > 0">
      <div
        v-for="(client, clientIndex) in clients"
        :key="client.id"
        class="client-form"
      >
        <div class="table-title-text" v-if="clientIndex == 0">Client</div>
        <div class="table-title-text" v-if="clientIndex == 0" id="client">
          {{ $t("label.travelForm.messageApp") }}
        </div>
        <div class="table-title-text" v-if="clientIndex == 0" id="client">
          {{ $t("label.travelForm.accompagnist") }}
        </div>
        <div class="client-name">
          {{ client.name + " " + client.surname }}
          <button
            @click="
              clients.splice(clientIndex, 1);
              accompagnist.splice(clientIndex, 1);
              accompagnistInput.splice(clientIndex, 1);
              messageApp.splice(clientIndex, 1);
            "
            v-if="tourAgency != ''"
            class="remove-button"
          >
            X
          </button>
        </div>
        <div class="message-app-select">
          <select v-model="messageApp[clientIndex]">
            <option
              v-for="application in filterMessageApp[clientIndex]"
              :value="application"
              :key="application.key"
            >
              {{ messageAppDisplay(application.app, application.identifier) }}
            </option>
            <option :value="{ app: 'other', identifier: '' }">
              {{ $t("label.other") }}
            </option>
          </select>
          <div v-if="messageApp[clientIndex].app == 'other'">
            <label for="new-app">App:</label>
            <select
              id="new-app"
              type="text"
              v-model="otherApp[clientIndex].app"
            >
              <option value="telegram">telegram</option>
              <option value="personal_matrix">Matrix</option>
            </select>
            <label for="new-identifier">{{
              $t("label.identifier") + ":"
            }}</label>
            <input type="text" v-model="otherApp[clientIndex].identifier" />
            <br />
            <button
              class="save-app-button"
              @click="
                saveAppToProfile(client, clientIndex, otherApp[clientIndex])
              "
            >
              save to profile
            </button>
          </div>
        </div>

        <div class="client-accompagnist">
          <div
            class="accompagnist-display"
            v-for="(accompagnistindiv, accompagnistIndex) in accompagnist[
              clientIndex
            ]"
            :key="accompagnistindiv.id"
          >
            {{ accompagnistindiv.name }}, {{ accompagnistindiv.age }}
            <button
              @click="accompagnist[clientIndex].splice(accompagnistIndex, 1)"
              class="remove-button"
            >
              X
            </button>
          </div>
          <label for="accompagnist-name-input">{{ $t("label.name") }}</label>
          <input
            type="text"
            id="accompagnist-name-input"
            v-model="accompagnistInput[clientIndex].name"
            @keypress.enter="
              accompagnist[clientIndex].push(accompagnistInput[clientIndex]);
              accompagnistInput[clientIndex] = { name: '', age: 1 };
            "
          />
          <label for="accompagnist-age-input">{{
            $t("label.travelForm.age")
          }}</label>
          <input
            type="number"
            id="accompagnist-age-input"
            v-model="accompagnistInput[clientIndex].age"
            @keypress.enter="
              accompagnist[clientIndex].push(accompagnistInput[clientIndex]);
              accompagnistInput[clientIndex] = { name: '', age: 1 };
            "
          />
        </div>
      </div>
    </div-->
    <br />
    <div
      v-if="
        tourAgency != '' &&
        this.$store.getters.userType == 'tourAgencyEmployee' &&
        this.$store.getters.tourAgencyId == tourAgency
      "
    >
      <p>{{ $t("label.travelForm.addClientTravel") }}</p>
      <v-autocomplete
        :items="items"
        :v-model="clientSearch"
        :get-label="getLabel"
        :component-item="clientTemplateSearch"
        @update-items="updateItems"
        :auto-select-one-item="false"
        @item-selected="addElement"
        placeholder="Search client..."
        id="add-client"
      ></v-autocomplete>
      <button class="new-client-button" @click="openNewClientModal">+</button>
      <modal
        v-if="false"
        name="newClient-modal"
        :scrollable="true"
        height="80%"
        width="60%"
        :adaptive="true"
        styles="overflow-y: scroll;"
      >
        <client-form
          :tourAgency="$store.getters.tourAgencyId"
          :noRedir="true"
          @clientcreated="loadAgencyClients"
        ></client-form>
      </modal>
    </div>
    <div
      v-if="
        (this.$store.getters.tourAgencyId == '' ||
          this.$store.getters.tourAgencyId == 'undefined') &&
        this.information.hasOwnProperty('name')
      "
    >
      <p>{{ $t("label.travelForm.inviteFriends") }}</p>
      <label for="friendInvite" @keypress.enter="inviteFriend()">{{
        $t("label.email")
      }}</label>
      <input type="email" id="friendInvite" v-model="inviteFriends" />
      <button
        type="button"
        @click="inviteFriend()"
        class="invite-friend-button"
        :style="{
          color: $store.getters.color.fontColor,
          backgroundColor: $store.getters.color.color2,
        }"
      >
        {{ $t("buttons.invite") }}
      </button>
    </div>

    <br />
    <div
      class="stopover-container"
      :style="{
        boxShadow: '1px 1px 5px ' + $store.getters.color.color3,
      }"
    >
      <p
        class="transportation-container-title"
        :style="{
          backgroundColor: $store.getters.color.color1,
          color: $store.getters.color.fontColor,
        }"
      >
        {{ $t("label.travelForm.stopover") }}
      </p>
      <div v-for="(stop, stopIndex) in stopover" :key="stop.id">
        <label for="stopPlace">{{ $t("label.travelForm.city") }}*</label>
        <input type="text" id="stopPlace" v-model="stop.place" required />
        <label for="stopStartDate"
          >{{ $t("label.travelForm.stopoverStartDate") }} *</label
        >
        <input
          type="date"
          id="stopStartDate"
          v-model="stop.startDate"
          required
        />
        <label for="stopEndDate"
          >{{ $t("label.travelForm.stopoverEndDate") }} *</label
        >
        <input type="date" id="stopEndDate" v-model="stop.endDate" required />
        <p class="error-message" v-if="messageDateList[stopIndex] != ''">
          {{ messageDateList[stopIndex] }} *
        </p>
        <label for="accomodationName"
          >{{ $t("label.travelForm.accomodation") }} *</label
        >
        <input
          type="text"
          id="accomodationName"
          v-model="stop.accomodationName"
          required
        />
        <label for="accomodationLocation"
          >{{ $t("label.travelForm.adress") }} *</label
        >
        <input
          type="text"
          id="accomodationLocation"
          v-model="stop.accomodationLocation"
          required
        />
        <button
          @click="stopover.splice(stopIndex, 1)"
          class="remove-button"
          v-if="stopIndex != 0"
        >
          X
        </button>
        <br />
        <div class="activity-edit" v-if="stopover.activities != []">
          <div
            v-for="(activity, activityIndex) in stop.activities"
            :key="activity.id"
          >
            <label for="activityType"
              >{{ $t("label.travelForm.booked") }}:</label
            >
            <select id="activityType" v-model="activity.genre">
              <option value="Restaurant">
                {{ $t("label.travelForm.restaurant") }}
              </option>
              <option value="Entertainment">
                {{ $t("label.travelForm.entertainement") }}
              </option>
              <option value="Transport">
                {{ $t("label.travelForm.transport") }}
              </option>
              <option value="Guide">{{ $t("label.travelForm.guide") }}</option>
              <option value="Performance">
                {{ $t("label.travelForm.performance") }}
              </option>
            </select>
            <label for="activityName">{{ $t("label.name") }}</label>
            <input type="text" id="activityName" v-model="activity.name" />
            <label for="activityDate">{{ $t("label.date") }}</label>
            <input type="date" id="activityDate" v-model="activity.date" />
            <label for="activityTime">{{ $t("label.time") }}</label>
            <input type="time" id="activityTime" v-model="activity.time" />
            <label for="activityAddress">{{
              activityInformationName(activity.genre)
            }}</label>
            <input
              type="text"
              id="activityAddress"
              v-model="activity.address"
            />
            <label for="activityInformation">{{
              $t("label.information")
            }}</label>
            <input
              type="text"
              id="activityInformation"
              v-model="activity.information"
            />
            <button
              @click="stop.activities.splice(activityIndex, 1)"
              class="remove-button"
            >
              X
            </button>
          </div>
        </div>
        <button
          @click="
            stop.activities.push({
              genre: 'Restaurant',
              name: '',
              date: niceDate(new Date(stop.startDate)),
              time: '12:00',
              address: '',
              information: '',
            })
          "
          class="add-button"
        >
          +
        </button>
      </div>
      <button
        @click="
          stopover.push({
            place: '',
            startDate: startDate,
            endDate: endDate,
            accomodationName: '',
            accomodationPlace: '',
            activities: [
              {
                genre: 'Restaurant',
                name: '',
                date: new Date(),
                time: '12:00',
                address: '',
                information: '',
              },
            ],
          })
        "
        class="new-stopover-button"
      >
        New Stopover
      </button>
      <br />
    </div>
    <div class="agency-option-display">
      <label
        for="own-app-check"
        v-if="
          this.$store.getters.userType == 'tourAgencyEmployee' &&
          Object.keys(this.information).length == 0
        "
        >{{ $t("label.travelForm.ownApp") }}</label
      >
      <input
        type="checkbox"
        id="own-app-check"
        v-model="ownApp"
        :disabled="ownAppDisabled"
        v-if="
          this.$store.getters.userType == 'tourAgencyEmployee' &&
          Object.keys(this.information).length == 0
        "
      />
      <br />
      <label
        for="watch-conversation"
        v-if="
          this.$store.getters.userType == 'tourAgencyEmployee' &&
          Object.keys(this.information).length == 0
        "
        >{{ $t("label.travelForm.watchConversation") }}</label
      >
      <input
        type="checkbox"
        id="watch-conversation"
        v-model="watchConversation"
        v-if="
          this.$store.getters.userType == 'tourAgencyEmployee' &&
          Object.keys(this.information).length == 0
        "
      />
    </div>

    <button
      id="confirmation-button"
      @click="submitEdit"
      :disabled="checkForm"
      class="save-button"
    >
      Save
    </button>
    <button
      type="button"
      class="back-button"
      @click="$emit('cancelEdit')"
      v-show="information.hasOwnProperty('name')"
    >
      Cancel
    </button>
  </div>
</template>

<script>
import HandleApi from "../apiHandle";
import clientTemplateSearch from "./ClientTemplateSearch.vue";
import sdk from "matrix-js-sdk";
import ClientForm from "./ClientForm";
import CountryList from "./CountryList.vue";

export default {
  name: "TravelForm",
  components: { ClientForm, CountryList },
  data() {
    return {
      inviteFriends: "",
      //name: this.information.name ? this.information.name : "",
      place: this.information.place ? this.information.place : "",
      startDate: this.information.startDate ? this.information.startDate : "",
      endDate: this.information.endDate ? this.information.endDate : "",
      locationTravelMean:
        this.information.locationTravelMean &&
        this.information.locationTravelMean.length > 0
          ? this.information.locationTravelMean
          : [{ mean: "plane", information: {} }],
      clients: this.information.clients ? this.information.clients : [],
      stopover:
        this.information.stopover && this.information.stopover.length > 0
          ? this.information.stopover
          : [
              {
                place: "",
                startDate: this.startDate,
                endDate: this.endDate,
                accomodationName: "",
                accomodationPlace: "",
                activities: [
                  {
                    genre: "Restaurant",
                    name: "",
                    date: this.niceDate(new Date(stop.startDate)),
                    time: "12:00",
                    address: "",
                    information: "",
                  },
                ],
              },
            ],
      accompagnist: this.information.accompagnist
        ? this.information.accompagnist
        : [],
      clientSearch: { _id: "", name: "", surname: "", messageApp: [] },
      clientTemplateSearch: clientTemplateSearch,
      items: this.newAgencyClient,
      accompagnistInput: [],
      messageApp: this.information.messageApp
        ? this.information.messageApp
        : "telegram",
      themeSelect: this.information.theme ? this.information.theme : [],
      otherThemeInput: "",
      newAgencyClient: this.agencyClients,
      travelConv: this.information.travelConv
        ? this.information.travelConv
        : "",
      roadmapUrl: this.information.roadmapUrl
        ? this.information.roadmapUrl
        : "",
      ownApp: this.information.ownApp ? this.information.ownApp : false,
      watchConversation: this.information.watchConversation
        ? this.information.watchConversation
        : false,
      agencyPairedApp: {},
      otherApp: this.information.otherApp ? this.information.otherApp : [],
      items: [],
    };
  },
  props: {
    tourAgency: {
      type: String,
      required: true,
    },
    agencyClients: {
      type: Array,
      required: true,
    },
    client: {
      type: Object,
      required: true,
    },
    information: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  computed: {
    filterMessageApp: function () {
      let res = [];
      for (let i = 0; i < this.clients.length; i++) {
        res.push(
          this.clients[i].messageApp.map((m) => {
            return { app: m.app, identifier: m.identifier };
          })
        );
      }
      return res;
    },
    ownAppDisabled: function () {
      return true;
      /*return !(
        this.messageApp.length > 0 &&
        this.messageApp[0].app != "matrix" &&
        this.agencyPairedApp[this.messageApp[0].app]
      );*/
    },
    theme: function () {
      if (this.themeSelect == "Other") {
        return this.otherThemeInput;
      } else {
        return this.themeSelect;
      }
    },
    errorContent: function () {
      if (new Date(this.endDate) < new Date(this.startDate)) {
        return this.$t("travel.dateError");
      }
      if (
        new Date(this.startDate) <
          new Date(new Date().setDate(new Date().getDate() + 2)) &&
        Object.keys(this.information).length == 0
      ) {
        return this.$t("travel.twoDaysPrior");
      }
      if (this.ownApp && this.watchConversation) {
        return this.$t("travel.optionError");
      }
      /*for (let i = 0; i < this.clients.length; i++) {
        let toCompare1 = this.messageApp[i].app;
        if (this.messageApp[i].app == "other") {
          toCompare1 = this.otherApp[i].app;
        }
        let toCompare2 = this.messageApp[0].app;
        if (this.messageApp[0].app == "other") {
          toCompare2 = this.otherApp[0].app;
        }
        if (
          toCompare1 != toCompare2 &&
          !(
            (toCompare1 == "matrix" && toCompare2 == "personal_matrix") ||
            (toCompare2 == "matrix" && toCompare1 == "personal_matrix")
          )
        ) {
          return this.$t("travel.appError");
        }
      }*/
      return "";
    },
    checkForm: function () {
      /*if (!this.name) {
        return true;
      }*/
      if (!this.place) {
        return true;
      }
      if (!this.theme) {
        return true;
      }
      if (!this.startDate) {
        return true;
      }
      if (!this.endDate) {
        return true;
      }
      /*for (let i = 0; i < this.clients.length; i++) {
        let toCompare1 = this.messageApp[i].app;
        if (this.messageApp[i].app == "other") {
          toCompare1 = this.otherApp[i].app;
        }
        let toCompare2 = this.messageApp[0].app;
        if (this.messageApp[0].app == "other") {
          toCompare2 = this.otherApp[0].app;
        }
        if (
          toCompare1 != toCompare2 &&
          !(
            (toCompare1 == "matrix" && toCompare2 == "personal_matrix") ||
            (toCompare2 == "matrix" && toCompare1 == "personal_matrix")
          )
        ) {
          return true;
        }
      }*/
      if (new Date(this.endDate) < new Date(this.startDate)) {
        return true;
      }
      if (this.ownApp && this.watchConversation) {
        return true;
      }
      if (this.clients.length == 0) {
        return true;
      }
      for (let i = 0; i < this.locationTravelMean.length; i++) {
        if (
          !this.locationTravelMean[i].information.from ||
          !this.locationTravelMean[i].information.to
        ) {
          return true;
        }
      }
      for (let i = 0; i < this.stopover.length; i++) {
        if (
          !(
            this.stopover[i].place &&
            this.stopover[i].startDate &&
            this.stopover[i].endDate &&
            this.stopover[i].accomodationName &&
            this.stopover[i].accomodationLocation
          )
        ) {
          return true;
        }
      }

      /*for (let i = 0; i < this.messageDateList.length; i++) {
        if (this.messageDateList[i] != "") {
          return true;
        }
      }*/
      return false;
    },
    messageDateList: function () {
      let res = [];
      for (let i = 0; i < this.stopover.length; i++) {
        let message = "";
        if (
          new Date(this.stopover[i].startDate) >
          new Date(this.stopover[i].endDate)
        ) {
          message = this.$t("travel.stopDateError");
        }
        if (new Date(this.startDate) > new Date(this.stopover[i].startDate)) {
          message = this.$t("travel.stopStartDateError");
        }
        if (new Date(this.endDate) < new Date(this.stopover[i].endDate)) {
          message = this.$t("travel.stopEndDateError");
        }
        res.push(message);
      }
      return res;
    },
  },
  methods: {
    deleteClient(i) {
      this.clients.splice(i, 1);
    },
    openNewClientModal() {
      this.$modal.show("newClient-modal");
    },
    async saveAppToProfile(client, clientIndex, app) {
      try {
        this.$store.dispatch("load", this.$t("loadMessage.saving"));
        let res = [];
        for (let i = 0; i < client.messageApp.length; i++) {
          res.push(client.messageApp[i]);
        }
        res.push(app);
        await HandleApi.putClient(client._id, {
          messageApp: res,
        });
        this.clients[clientIndex].messageApp.push(app);
        //this.messageApp[clientIndex] = app;
        this.otherApp[clientIndex] = { app: "", identifier: "" };
        this.$store.dispatch("unload");
        this.$store.dispatch(
          "push_notification_message",
          this.$t("notification.saved", { object: "app" })
        );
      } catch (err) {
        this.$store.dispatch("push_error_message", err.response.data || err);
      }
    },
    messageAppDisplay(app, identifier) {
      switch (app) {
        case "matrix":
          if (
            this.$store.getters.tourAgencyId !=
            this.$store.getters.travelAssistAgencyId
          ) {
            return this.$t("label.geniusTravelTchat");
          } else {
            return this.$t("label.TATchat");
          }
        case "personal_matrix":
          return "matrix (" + identifier + ")";
        default:
          return app + " (" + identifier + ")";
      }
    },
    niceDate: function (dat) {
      let month = dat.getMonth();
      if (month < 9) {
        month = "0" + (month + 1);
      } else {
        month = month + 1;
      }
      let date = dat.getDate();
      if (date < 10) {
        date = "0" + date;
      }
      return dat.getFullYear() + "-" + month + "-" + date;
    },
    submitEdit: async function () {
      try {
        this.$store.dispatch("load", this.$t("loadMessage.createTravel"));
        let dataClient = [];
        for (let i = 0; i < this.clients.length; i++) {
          dataClient.push({
            client_id: this.clients[i]._id,
            accompagnist: this.accompagnist[i],
            /*messageApp:
              this.messageApp[i].app == "other"
                ? this.otherApp[i]
                : this.messageApp[i],*/
          });
        }
        let newData = {
          //name: this.name,
          place: this.place,
          country: this.$store.state.countrySelector,
          startDate: this.niceDate(new Date(this.startDate)), // yyyy-mm-dd
          endDate: this.niceDate(new Date(this.endDate)),
          stopover: this.stopover,
          client: dataClient,
          theme: this.theme,
          locationTravelMean: this.locationTravelMean,
          ownApp: this.ownApp,
          watchConversation: this.watchConversation,
          messageApp: this.messageApp,
        };
        if (this.tourAgency) {
          newData["tourAgency"] = this.tourAgency;
        }
        if (this.$store.getters.affiliation != "") {
          newData["affiliation"] = this.$store.getters.affiliation;
        }
        if (!this.$route.params.id) {
          try {
            let res = await HandleApi.checkAndGetPrice(newData);
            if (res.data.price == 0) {
              newData.travelTest = true;
              let newRes = await HandleApi.postTravel(newData);
              this.$store.dispatch("unload");
              this.$router.push(
                "/travel/" + newRes.data.travelId + "?roadmapUpload=true"
              );
            } else {
              this.$store.dispatch("unload");
              this.$router.push({
                name: "checkout",
                params: {
                  travelData: newData,
                  price: res.data.price,
                  payment: res.data.payment,
                },
              });
            }
          } catch (err) {
            this.$store.dispatch("unload");
            this.$store.dispatch(
              "push_error_message",
              err.response.data || err
            );
          }
        } else {
          let res = await HandleApi.putTravel(this.$route.params.id, newData);
          this.$store.dispatch("unload");
          this.$store.dispatch(
            "push_notification_message",
            this.$t("notification.updated", {
              object: this.$t("notification.travel"),
            })
          );
          this.$router.go("/travel/" + res.data);
        }
      } catch (err) {
        this.$store.dispatch("unload");
        this.$store.dispatch("push_error_message", err);
      }
    },
    getLabel(item) {
      return item.name + " " + item.surname;
    },
    updateItems(text) {
      this.items = this.newAgencyClient.filter((client) =>
        (client.name + " " + client.surname).includes(text)
      );
    },
    addElement(item) {
      if (!this.clients.includes(item) && this.clients.length < 3) {
        this.clients.push(item);
        this.accompagnist.push([]);
        this.accompagnistInput.push({ name: "", age: 1 });
        //this.messageApp.push(item.messageApp[0]);
        this.otherApp.push({ app: "", identifier: "" });
      }
    },
    activityInformationName(activityType) {
      switch (activityType) {
        case "Transport":
          return this.$t("label.travelForm.company");
        case "Guide":
          return this.$t("label.phone");
        default:
          return this.$t("label.travelForm.adress");
      }
    },
    async inviteFriend() {
      try {
        let clientName = "";
        for (let i = 0; i < this.clients.length; i++) {
          if (this.clients[i]._id == this.$store.getters.userId) {
            clientName = this.clients[i].surname + " " + this.clients[i].name;
          }
        }
        HandleApi.inviteToTravel(this.$route.params.id, {
          toEmail: this.inviteFriends,
          travelName: this.name,
          location: this.place,
          password: this.password,
          name: clientName,
        });
        this.inviteFriends = "";
      } catch (err) {
        this.$store.dispatch("push_error_message", err);
      }
    },
    async parseUploadedFile(event) {
      try {
        if (this.travelConv) {
          this.$store.dispatch("load", "Uploading your roadmap");
          let res = undefined;
          if (this.$store.getters.userType == "client") {
            res = await HandleApi.getMessageAuth(this.$store.getters.userId);
          } else {
            res = await HandleApi.getTravelMatrix(this.$route.params.id);
          }
          let client = sdk.createClient({
            baseUrl: res.baseUrl,
            accessToken: res.accessToken,
            userId: res.userId,
          });
          let file = event.target.files[0];
          this.roadmapUrl = await client.uploadContent(file, this.travelConv);
          let docUrl = this.roadmapUrl.substring(6);
          this.roadmapUrl =
            res.baseUrl + "/_matrix/media/r0/download/" + docUrl;
          await client.sendEvent(
            this.travelConv,
            "m.room.message",
            {
              body: "A Roadmap has been uploaded for this travel",
              msgtype: "m.notice",
              format: "org.matrix.custom.html",
              formatted_body:
                "<p>" +
                "A Roadmap has been uploaded for this travel you can look at it here: " +
                "<a href='" +
                this.roadmapUrl +
                "'>Roadmap</a>" +
                "</p>",
            },
            ""
          );
          await HandleApi.uploadRoadmap(this.$route.params.id, {
            roadmap: this.roadmapUrl,
          });
          this.$store.dispatch(
            "push_notification_message",
            this.$t("notification.updated", {
              object: this.$t("notification.roadmap"),
            })
          );
          this.$store.dispatch("unload");
        }
      } catch (err) {
        this.$store.dispatch("push_error_message", err);
        this.$store.dispatch("unload");
      }
    },
    async loadAgencyClients() {
      this.$store.dispatch("load", this.$t("loadMessage.clientList"));
      let res = await HandleApi.getAgencyInformation(
        this.$store.getters.tourAgencyId
      );
      this.newAgencyClient = res.clients;
      this.items = res.clients;
      this.agencyClients = res.clients;
      this.agencyPairedApp = res.pairedApp;
      for (let i = 0; i < this.clients.length; i++) {
        this.accompagnistInput.push({ name: "", age: 1 });
        /*if (!this.information.messageApp) {
          this.messageApp.push(this.clients[i].messageApp[0]);
        }*/
      }
      this.$store.dispatch("unload");
    },
  },
  async created() {
    if (this.$store.getters.userType === "tourAgencyEmployee") {
      this.loadAgencyClients();
    } else {
      if (this.clients.length == 0) {
        this.clients.push(this.client);
      }
      for (let i = 0; i < this.clients.length; i++) {
        this.accompagnistInput.push({ name: "", age: 1 });
        /*if (!this.information.messageApp) {
          this.messageApp.push(this.clients[i].messageApp[0]);
        }*/
      }
    }
  },
};
</script>

<style scoped>
.edit-div {
  text-align: center;
  margin-top: 20px;
  line-height: 40px;
}

.edit-div label {
  margin-right: 10px;
  margin-left: 10px;
}

.error-message {
  border: 2px solid red;
  color: red;
  background-color: #ecb2b2;
  width: 500px;
  margin: 0 auto;
  border-radius: 10px;
}

.intro-mean-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 30px;
  width: 100%;
}

@media only screen and (max-width: 1300px) {
  .intro-mean-container {
    display: block;
    margin-bottom: 30px;
  }

  .travel-intro {
    margin-bottom: 20px;
  }
}

.travel-intro {
  display: inline-block;
  box-shadow: 1px 1px 5px #034c57;
  border-radius: 10px;
  margin-right: 20px;
}

.travel-intro input {
  margin-right: 5px;
}

.transportation-container-title {
  margin: 0;
  padding: 20px;
  font-size: 25px;
  color: white;
  font-weight: bold;
  background-color: #00909f;
  border-radius: 10px 10px 0px 0px;
  text-align: left;
  margin-bottom: 10px;
}

.location-travel-mean-container {
  display: inline-block;
  box-shadow: 1px 1px 5px #034c57;
  border-radius: 10px;
  margin-bottom: 5px;
}

.location-travel-mean {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.location-travel-mean input {
  margin-right: 10px;
}

.transport-information {
  display: inline-block;
  margin: 10px;
  margin-top: 0;
}

.app-container {
  display: inline-block;
}

.remove-button {
  margin: 10px;
  color: white;
  height: 21px;
  width: 21px;
  text-align: center;
  background-color: #ff3a3a;
  border-radius: 30px;
  border: none;
  padding: 0;
}

.add-button {
  background-color: rgb(55, 187, 248);
  color: white;
  border: none;
  border-radius: 3px;
  height: 20px;
  font-weight: bold;
  width: 30px;
}

.new-stopover-button {
  background-color: rgb(163, 69, 226);
  color: white;
  border: none;
  border-radius: 10px;
  margin-right: 10px;
  margin-top: 20px;
  font-weight: bold;
  padding: 5px 20px;
  font-size: 20px;
}

.activity-edit {
  display: inline-block;
  background-color: rgb(235, 235, 235);
  border-radius: 10px;
  margin-right: 10px;
}

.save-button {
  background-color: rgb(9, 201, 111);
  color: white;
  border: none;
  border-radius: 10px;
  margin-right: 10px;
  margin-top: 20px;
  font-weight: bold;
  padding: 5px 20px;
  font-size: 20px;
}

.save-button:disabled {
  background-color: rgb(139, 196, 169);
  color: white;
  border: none;
  border-radius: 10px;
  margin-right: 10px;
  margin-top: 20px;
  font-weight: bold;
  padding: 5px 20px;
  font-size: 20px;
}

.back-button {
  background-color: rgb(255, 72, 72);
  color: white;
  border: none;
  border-radius: 10px;
  margin-left: 10px;
  margin-top: 20px;
  font-weight: bold;
  padding: 5px 20px;
  font-size: 20px;
}

.errorField {
  -moz-box-shadow: 0 0 3px red;
  -webkit-box-shadow: 0 0 3px red;
  box-shadow: 0 0 3px red;
}

.big-form {
  border: 2px solid rgb(226, 226, 226);
  border-radius: 10px;
  background-color: rgb(226, 226, 226);
  margin: 0 auto;
  display: inline-block;
}

.client-form {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 10px;
  box-shadow: 3px 3px 3px lightgrey;
}

.table-title-text {
  color: black;
  border-bottom: 2px solid white;
}

.table-title-text#client {
  border-left: 2px solid white;
  padding: 5px;
}

.client-name {
  justify-self: center;
  align-self: center;
  display: block;
}

.message-app-select {
  border-left: 2px solid white;
}

.client-accompagnist {
  border-left: 2px solid white;
}

#add-client {
  display: inline-block;
}

.invite-friend-button {
  color: white;
  font-weight: bold;
  margin-left: 10px;
  background-color: #abdbe9;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
}

.stopover-container {
  box-shadow: 1px 1px 5px #034c57;
  border-radius: 10px;
  margin-left: 30px;
  margin-right: 30px;
}

.upload-documents {
  padding: 5px;
  border-radius: 10px;
  color: white;
  background-color: #00909f;
  font-weight: bold;
}

.upload-documents:hover {
  text-decoration: underline;
  cursor: pointer;
}

.roadmap {
  margin-bottom: 10px;
}

.new-client-button {
  background-color: #009fbc;
  color: white;
  border: none;
  border-radius: 15px;
  height: 30px;
  width: 30px;
  padding: 5px;
  margin-left: 10px;
}

.save-app-button {
  background-color: #008467;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 5px;
}

.clients {
  padding: 10px;
  background-color: rgb(226, 226, 226);
}
.delete {
  margin-left: 25px;
  color: red;
  cursor: pointer;
  text-transform: capitalize;
}
</style>
